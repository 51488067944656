$(function(){
    // calc start
    function reCalc() {
        var price = 0;

        var profil = $('#calcType3').val();

        // get house type
        var house = $('#calcType1').val();
        //if (house === 'house2') {
        //price += 920;
        //}

        // get windows type
        var windowType = parseInt( $('.b-calc__window-active').data('window') );
        var steklopaket = $('#calcType2').val();
        switch (windowType) {
            // первое окно (двустворчатое)
            case 1:
                // для литовок
                if (house === 'house1') {
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 7750;
                                price += 6850;
                                price += 800;
                            } else {
                                price += 6850;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 8350;
                                price += 7750;
                                price += 800;
                            } else {
                                price += 7750;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 9410;
                                price += 8450;
                                price += 800;
                            } else {
                                price += 8450;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 9630;
                                price += 9130;
                                price += 800;
                            } else {
                                price += 9130;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 9580;
                                price += 8740;
                                price += 800;
                            } else {
                                price += 8740;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 10450;
                                price += 9930;
                                price += 800;
                            } else {
                                price += 9930;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 9552;
                                price += 8801;
                                price += 800;
                            } else {
                                price += 8801;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 9857;
                                price += 9469;
                                price += 800;
                            } else {
                                price += 9469;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 10321;
                                price += 9940;
                                price += 800;
                            } else {
                                price += 9940;
                            }
                            break;
                    }
                } else {
                    // для хрущёвок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 6600;
                                price += 5930;
                                price += 800;
                            } else {
                                price += 5930;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 7170;
                                price += 6590;
                                price += 800;
                            } else {
                                price += 6590;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 7980;
                                price += 7310;
                                price += 800;
                            } else {
                                price += 7310;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 8270;
                                price += 7890;
                                price += 800;
                            } else {
                                price += 7890;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 8230;
                                price += 7590;
                                price += 800;
                            } else {
                                price += 7590;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 9000;
                                price += 8620;
                                price += 800;
                            } else {
                                price += 8620;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 8322;
                                price += 7733;
                                price += 800;
                            } else {
                                price += 7733;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 8602;
                                price += 8301;
                                price += 800;
                            } else {
                                price += 8301;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 9016;
                                price += 8721;
                                price += 800;
                            } else {
                                price += 8721;
                            }
                            break;
                    }
                }
                break;
            case 2:
                // второе окно (трёхстворчатое)
                if (house === 'house1') {
                    // для литовок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 11210;
                                price += 9780;
                                price += 800;
                            } else {
                                price += 9780;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 12000;
                                price += 11120;
                                price += 800;
                            } else {
                                price += 11120;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 12920;
                                price += 11520;
                                price += 800;
                            } else {
                                price += 11520;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 13320;
                                price += 12520;
                                price += 800;
                            } else {
                                price += 12520;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 13340;
                                price += 11990;
                                price += 800;
                            } else {
                                price += 11990;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 14440;
                                price += 13630;
                                price += 800;
                            } else {
                                price += 13630;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 13009;
                                price += 11800;
                                price += 800;
                            } else {
                                price += 11800;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 13393;
                                price += 12768;
                                price += 800;
                            } else {
                                price += 12768;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 13957;
                                price += 13339;
                                price += 800;
                            } else {
                                price += 13339;
                            }
                            break;
                    }
                } else {
                    // для хрущёвок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 9850;
                                price += 8700;
                                price += 800;
                            } else {
                                price += 8700;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 10570;
                                price += 9580;
                                price += 800;
                            } else {
                                price += 9580;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 11280;
                                price += 10120;
                                price += 800;
                            } else {
                                price += 10120;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 11670;
                                price += 11000;
                                price += 800;
                            } else {
                                price += 11000;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 11690;
                                price += 10570;
                                price += 800;
                            } else {
                                price += 10570;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 12680;
                                price += 12010;
                                price += 800;
                            } else {
                                price += 12010;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 11723;
                                price += 10713;
                                price += 800;
                            } else {
                                price += 10713;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 12088;
                                price += 11568;
                                price += 800;
                            } else {
                                price += 11568;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 12602;
                                price += 12090;
                                price += 800;
                            } else {
                                price += 12090;
                            }
                            break;
                    }
                }
                break;
            case 3:
                // трёхстворчатое с двумя открывающимися створками
                if (house === 'house1') {
                    // для литовок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 12460;
                                price += 11130;
                                price += 800;
                            } else {
                                price += 11130;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 13460;
                                price += 12360;
                                price += 800;
                            } else {
                                price += 12360;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 14800;
                                price += 13520;
                                price += 800;
                            } else {
                                price += 13520;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 15320;
                                price += 14580;
                                price += 800;
                            } else {
                                price += 14580;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 15230;
                                price += 13970;
                                price += 800;
                            } else {
                                price += 13970;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 16630;
                                price += 15880;
                                price += 800;
                            } else {
                                price += 15880;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 15478;
                                price += 14353;
                                price += 800;
                            } else {
                                price += 14353;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 15999;
                                price += 15418;
                                price += 800;
                            } else {
                                price += 15418;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 16732;
                                price += 16160;
                                price += 800;
                            } else {
                                price += 16160;
                            }
                            break;
                    }
                } else {
                    // для хрущёвок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 11400;
                                price += 10320;
                                price += 800;
                            } else {
                                price += 10320;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 12320;
                                price += 11410;
                                price += 800;
                            } else {
                                price += 11410;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 13010;
                                price += 11950;
                                price += 800;
                            } else {
                                price += 11950;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 13490;
                                price += 12880;
                                price += 800;
                            } else {
                                price += 12880;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 13410;
                                price += 12380;
                                price += 800;
                            } else {
                                price += 12380;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 14710;
                                price += 14100;
                                price += 800;
                            } else {
                                price += 14100;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 13923;
                                price += 12990;
                                price += 800;
                            } else {
                                price += 12990;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 14414;
                                price += 13935;
                                price += 800;
                            } else {
                                price += 13935;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 15083;
                                price += 14613;
                                price += 800;
                            } else {
                                price += 14613;
                            }
                            break;
                    }
                }
                break;
            case 4:
                // балконный блок с глухим окном
                if (house === 'house1') {
                    // для литовок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 12470;
                                price += 11000;
                                price += 800;
                            } else {
                                price += 11000;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 13470;
                                price += 12240;
                                price += 800;
                            } else {
                                price += 12240;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 14520;
                                price += 13090;
                                price += 800;
                            } else {
                                price += 13090;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 15015;
                                price += 14200;
                                price += 800;
                            } else {
                                price += 14200;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 14980;
                                price += 13580;
                                price += 800;
                            } else {
                                price += 13580;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 16260;
                                price += 15440;
                                price += 800;
                            } else {
                                price += 15440;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 15703;
                                price += 14447;
                                price += 800;
                            } else {
                                price += 14447;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 16133;
                                price += 15498;
                                price += 800;
                            } else {
                                price += 15498;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 17391;
                                price += 16764;
                                price += 800;
                            } else {
                                price += 16764;
                            }
                            break;
                    }
                } else {
                    // для хрущёвок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 11200;
                                price += 10040;
                                price += 800;
                            } else {
                                price += 10040;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 12100;
                                price += 11150;
                                price += 800;
                            } else {
                                price += 11150;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 12930;
                                price += 11800;
                                price += 800;
                            } else {
                                price += 11800;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 13400;
                                price += 12770;
                                price += 800;
                            } else {
                                price += 12770;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 13360;
                                price += 12270;
                                price += 800;
                            } else {
                                price += 12270;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 14610;
                                price += 13600;
                                price += 800;
                            } else {
                                price += 13600;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 14049;
                                price += 13363;
                                price += 800;
                            } else {
                                price += 13363;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 14469;
                                price += 13971;
                                price += 800;
                            } else {
                                price += 13971;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 15230;
                                price += 14739;
                                price += 800;
                            } else {
                                price += 14739;
                            }
                            break;
                    }
                }
                break;
            case 5:
                // балконный блок с откидной створкой
                if (house === 'house1') {
                    // для литовок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 14100;
                                price += 13200;
                                price += 800;
                            } else {
                                price += 13200;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 15420;
                                price += 14500;
                                price += 800;
                            } else {
                                price += 14500;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 16980;
                                price += 16150;
                                price += 800;
                            } else {
                                price += 16150;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 17640;
                                price += 17370;
                                price += 800;
                            } else {
                                price += 17370;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 17550;
                                price += 16770;
                                price += 800;
                            } else {
                                price += 16770;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 19300;
                                price += 19020;
                                price += 800;
                            } else {
                                price += 19020;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 19002;
                                price += 17844;
                                price += 800;
                            } else {
                                price += 17844;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 19634;
                                price += 19058;
                                price += 800;
                            } else {
                                price += 19058;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 20626;
                                price += 20062;
                                price += 800;
                            } else {
                                price += 20062;
                            }
                            break;
                    }
                } else {
                    // для хрущёвок
                    switch (profil) {
                        case 'plafen60':
                            if (steklopaket === 'glass3') {
                                //price += 13750;
                                price += 12550;
                                price += 800;
                            } else {
                                price += 12550;
                            }
                            break;
                        case 'plafen70':
                            if (steklopaket === 'glass3') {
                                //price += 14890;
                                price += 12880;
                                price += 800;
                            } else {
                                price += 12880;
                            }
                            break;
                        case 'brusbox60':
                            if (steklopaket === 'glass3') {
                                //price += 15450;
                                price += 14450;
                                price += 800;
                            } else {
                                price += 14450;
                            }
                            break;
                        case 'brusbox70':
                            if (steklopaket === 'glass3') {
                                //price += 16070;
                                price += 15505;
                                price += 800;
                            } else {
                                price += 15505;
                            }
                            break;
                        case 'rehau60':
                            if (steklopaket === 'glass3') {
                                //price += 16000;
                                price += 15030;
                                price += 800;
                            } else {
                                price += 15030;
                            }
                            break;
                        case 'rehau70':
                            if (steklopaket === 'glass3') {
                                //price += 17630;
                                price += 17050;
                                price += 800;
                            } else {
                                price += 17050;
                            }
                            break;
                        case 'vekaeuro':
                            if (steklopaket === 'glass3') {
                                //price += 17294;
                                price += 16663;
                                price += 800;
                            } else {
                                price += 16663;
                            }
                            break;
                        case 'vekapro':
                            if (steklopaket === 'glass3') {
                                //price += 17891;
                                price += 17447;
                                price += 800;
                            } else {
                                price += 17447;
                            }
                            break;
                        case 'vekasoft':
                            if (steklopaket === 'glass3') {
                                //price += 18816;
                                price += 18382;
                                price += 800;
                            } else {
                                price += 18382;
                            }
                            break;
                    }
                }
                break;
        }

        // podokonnik
        if ($('#options_c_1').hasClass('btn-success')) {
            //фикс в зависимости от типа окна и типа дома:
            //первое окно: +453 за литовку, +700 за хрущёвку
            //второе окно: +652 за литовку, +1058 за хрущёвку
            //третье окно: +652 за литовку, +1058 за хрущёвку
            //четвёртое окно: +453 за литовку, +700 за хрущёвку
            //пятое окно: +453 за литовку, + 700 за хрущёвку
            switch (windowType) {
                case 1:
                    if (house === 'house1') {
                        price += 453;
                    } else {
                        price += 700;
                    }
                    break;
                case 2:
                    if (house === 'house1') {
                        price += 652;
                    } else {
                        price += 1058;
                    }
                    break;
                case 3:
                    if (house === 'house1') {
                        price += 652;
                    } else {
                        price +=1058;
                    }
                    break;
                case 4:
                    if (house === 'house1') {
                        price += 453;
                    } else {
                        price += 700;
                    }
                    break;
                case 5:
                    if (house === 'house1') {
                        price += 453;
                    } else {
                        price += 700;
                    }
                    break;
            }
        }
        // otkos
        if ($('#options_c_2').hasClass('btn-success')) {
            //первое окно, литовка: 1720, хрущёвка: 1950
            //второе окно, литовка: 2040, хрущёвка: 2350
            //третье окно, литовка: 2040, хрущёвка: 2350
            //четвёртое окно, литовка: 2900, хрущёвка: 3534
            //пятое окно, литовка: 2900, хрущёвка: 3534
            switch (windowType) {
                case 1:
                    if (house === 'house1') {
                        price += 1720;
                    } else {
                        price += 1950;
                    }
                    break;
                case 2:
                    if (house === 'house1') {
                        price += 2040;
                    } else {
                        price += 2350;
                    }
                    break;
                case 3:
                    if (house === 'house1') {
                        price += 2040;
                    } else {
                        price += 2350;
                    }
                    break;
                case 4:
                    if (house === 'house1') {
                        price += 2900;
                    } else {
                        price += 3534;
                    }
                    break;
                case 5:
                    if (house === 'house1') {
                        price += 2900;
                    } else {
                        price += 3534;
                    }
                    break;
            }
        }
        // otliv
        if ($('#options_c_3').hasClass('btn-success')) {
            //первое окно: 180 за литовку, 178 за хрущёвку
            //второе окно: 276 за литовку, 287 за хрущёвку
            //третье окно: 276 за литовку, 287 за хрущёвку
            //чертвёртое окно: 185 за литовку, 178 за хрущёвку
            //пятое окно: 185 за литовку, 178 за хрущёвку
            switch (windowType) {
                case 1:
                    if (house === 'house1') {
                        price += 180;
                    } else {
                        price += 178;
                    }
                    break;
                case 2:
                    if (house === 'house1') {
                        price += 276;
                    } else {
                        price += 287;
                    }
                    break;
                case 3:
                    if (house === 'house1') {
                        price += 276;
                    } else {
                        price += 287;
                    }
                    break;
                case 4:
                    if (house === 'house1') {
                        price += 185;
                    } else {
                        price += 178;
                    }
                    break;
                case 5:
                    if (house === 'house1') {
                        price += 185;
                    } else {
                        price += 178;
                    }
                    break;
            }
        }
        // moskitnaya setka
        if ($('#options_c_4').hasClass('btn-success')) {
            price += 500;
        }
        // montazh
        if ($('#options_c_5').hasClass('btn-success')) {
            price += price * 0.1;
        }

        // deshevle na 500 rubley
        price += -500;

        // display price
        $('#show_price').text(price + ' руб.');
    }

// initial call
    reCalc();

    $('.b-calc__window').on('click', function() {
        $('.b-calc__window').removeClass('b-calc__window-active');
        $(this).addClass('b-calc__window-active');

        var windowType = parseInt( $(this).data('window') );
        $('#fullWindow').attr('src', 'assets/i/windows/big/window-' + windowType + '.svg');

        reCalc();
    });

    $('#options_c label').on('click', function(e) {

        if(e.target.classList[0] === 'label' || e.target.classList[0] === 'checkbox-custom'){
            $(this).find('.checkbox-custom').toggleClass('btn-outline-danger').toggleClass('btn-success');
            reCalc();
        }

    });

    $('#calcType1').on('change', function() {
        reCalc();
    });
    $('#calcType2').on('change', function() {
        reCalc();
    });
    $('#calcType3').on('change', function() {
        reCalc();
    });
// calc end

    /* calc circle
    ====================================================*/
    $('#js-windows__type img').on('click', function(){
        let circle = '<div class="circle"></div>';

        if($(this).parent().attr('class') !== 'img-wrap'){
            $(this).wrap('<div class="img-wrap"></div>');
        }

        if($(this).next().attr('class') !== 'circle'){
            $(circle).insertAfter($(this));
        }
    });

    /* form styler
    ====================================================*/
    //http://dimox.name/jquery-form-styler

    $('select').styler({
        onSelectOpened: function() {
            // к открытому селекту добавляется красная обводка
            //console.log($(this));
        }
    });
});